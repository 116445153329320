import React from 'react';
import Home from './Home';
import RSVP from './RSVP';
import Recommendations from './Recommendations';
import IvoryButton from "./IvoryButton";
import Details from "./Details";
import Clothes from "./Clothes";
import ReceptionRSVP from './ReceptionRSVP';
import ReceptionDetails from './ReceptionDetails';
import ReceptionClothes from './ReceptionClothes';

const buttonConfigs = [
    { label: 'Details', component: 'details', bottom: '40%' },
    { label: 'RSVP', component: 'rsvp', bottom: '30%' },
    { label: 'What to Wear', component: 'clothes', bottom: '20%' },
    { label: 'London', component: 'recommendations', bottom: '10%' }
];

function Main(props) {
    const [currentComponent, setCurrentComponent] = React.useState('home');
    const fullGuest = props.fullGuest;
    console.log("Full guest = ", fullGuest)

    const getComponent = () => {
        switch (currentComponent) {
            case 'home':
                return <Home />;
            case 'rsvp':
                return fullGuest ? <RSVP /> : <ReceptionRSVP />;
            case 'recommendations':
                return <Recommendations />;
            case 'details':
                return fullGuest ? <Details /> : <ReceptionDetails />;
            case 'clothes':
                return fullGuest ? <Clothes /> : <ReceptionClothes />;
            default:
                return <Home />;
        }
    };

    return (
        <div className="main-container">
            {currentComponent === "home" ? (
                <div>
                    <div className='HomeImage'>
                        {buttonConfigs.map(({ label, component, bottom }) => (
                            <IvoryButton
                                key={component}
                                variant="outlined"
                                sx={{ position: "absolute", left: "80%", bottom }}
                                onClick={() => setCurrentComponent(component)}
                            >
                                {label}
                            </IvoryButton>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="button-container">
                    <IvoryButton
                        key='home'
                        variant="outlined"
                        onClick={() => setCurrentComponent('home')}
                    >
                        Home
                    </IvoryButton>
                    {buttonConfigs.map(({ label, component }) => (
                        <IvoryButton
                            key={component}
                            variant="outlined"
                            onClick={() => setCurrentComponent(component)}
                        >
                            {label}
                        </IvoryButton>
                    ))}
                </div>
            )}

            <div className="component-container">
                {getComponent()}
            </div>
        </div>
    );
}

export default Main;
