import chelsea from "../img/chelsea.jpg";
import React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

function ReceptionDetails() {
    return <div className="container">
        <img src={chelsea} className="HomeImageDesktop" alt="Chelsea Bridge at night"/>
        <img src={chelsea} className="HomeImageMobile" alt="Chelsea Bridge at night"/>
        <div className="text-container">

            <CssBaseline/>
            <Card
                variant="outlined"
                sx={{
                    paddingLeft: "15px", paddingRight: "15px",
                    marginLeft: "5%", marginRight: "5%",
                    marginTop: "90px"
                }}
                style={{backgroundColor: "rgba(10,30,104,0.6)"}}
            >
                <Typography variant="h3" component="h1" color={"ivory"} gutterBottom align={"center"}
                            padding={"20px"} fontFamily={"Goudy"}>
                    Saturday 24th May 2025
                </Typography>
                <Typography variant="h2" component="h1" color={"ivory"} gutterBottom align={"center"}
                            padding={"20px"} fontFamily={"Goudy"}>
                    The Bridge Room, The Prince Albert, Battersea
                </Typography>
                <Typography variant="h4" component="h1" color={"ivory"} gutterBottom align={"center"}
                            padding={"20px"} fontFamily={"Avenir"}>
                    Arrive any time from: 18:30pm, Cake cutting at: 19:00pm
                </Typography>
                <Typography variant="h5" component="h2" color={"ivory"} gutterBottom align={"center"}
                            padding={"20px"} fontFamily={"Avenir"}>
                    {'Please note that these timings are only a guideline and we may end up running late.'}<br/>
                    {'If that is the case then please feel free to either join us in the hall or wait in the bar downstairs'}
                </Typography>
            </Card>

        </div>
    </div>
}

export default ReceptionDetails;